import React, { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";

import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button } from "@mui/material";
import ReactSelect from "react-select";

import {
  Loading,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,
} from "react-admin";
import { useParams } from "react-router-dom";
import save_icon from "../../../assets/images/save_icon.svg";


const ViewReportDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const notify = useNotify();
  const [create] = useCreate();
  const refresh = useRefresh();
  const { data: dataField } = useGetList("GETDataFiled");
  const [dataFieldEnabled, setDataFieldEnabled] = useState(true);
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  console.log("id 2",id)
  const {
    data: getScreeningReportDetails,
    isLoading: loadingEmailSettingsDetails,
  } = useGetList(
    "screening-report-list-view",
    {
      filter: {
        subscriberID: 10301,
        officeId: id,
      },
    },
    {
      enabled: switchSubscriberObj?.value && id ? true : false,
      refetchOnWindowFocus: false,
    }
  );

 
 

 
  console.log("getScreeningReportDetails",getScreeningReportDetails)
 
  if (loadingEmailSettingsDetails) return <Loading />;
  return (
    <>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Typography sx={{ fontWeight: "bold" }}>
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.38)" }}
                  onClick={() => navigate(-1)}
                >
                 Screening Report
                </Typography>
              </Typography>
              <Typography color="textPrimary" style={{ fontWeight: "bold" }}>
                Report Details
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default ViewReportDetails;

import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Button as MuiButton,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Button,
  Divider,
} from "@mui/material";

import InputField2 from "../../common/InputComponents/InputField2";
import {
  useGetList,
  useDataProvider,
  useCreate,
  useGetIdentity,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

interface ApplicantInfo {
  monthOfCurrentResidenc: number;
  monthlyIncome: number;
  monthsEmployed: number;
  savingAccountBalance: number;
  applicantId: number;
}

interface Payload {
  trnID: string;
  selectedReports: string[];
}

const ProcessScreeningModal = ({ appRecords }) => {
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const notify = useNotify();
  const [create, { isLoading, error }] = useCreate();
  const [psReportsData, setPsReportsData] = useState<
    {
      name: string;
      forceOrder: number | boolean;
      preSelected: number | boolean;
    }[]
  >([]);
  const [applicantData, setapplicantData] = useState<any[]>([]);
  const [subscriberPsData, setsubscriberPsData] = useState<any[]>([]);
  const [validateReports, setValidateReports] = useState(true);
  const handleOpen = () => {
    fetchApplicantsListDetails();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // const testReports = ["checck1","check2","Check3"]

  // console.log("applicantData",applicantData)
  // console.log("subscriberId",subscriberId)
  // console.log("propertyId",propertyId)

  // Extract applicant names from ApplicantList
  // const applicantNames = applicantData?.ApplicantList?.split(",") || [];
  // console.log("applicantData 3",applicantData)
  const applicantNames = ["Applicant", "co-applicant", "co-signer"];

  const baseHeight = 200; // Base height for the modal
  const perApplicantHeight = 120; // Estimated height per applicant section
  const calculatedHeight =
    baseHeight + applicantNames.length * perApplicantHeight;

  // Initialize default form values and validation schema dynamically
  const defaultFormValues = {
    reports: [] as string[],
  };

  useEffect(() => {
    if (
      subscriberPsData[0]?.paymentMethod === "endOfTheMonthBilling" ||
      subscriberPsData[0]?.paymentMethod === "applicationCollect"
    ) {
      setValidateReports(false);
    } else {
      setValidateReports(true);
    }
  }, [subscriberPsData]);

  // get predictscreen reports
  const fetchScreeningReportListDetails = async () => {
    const { data } = await dataProvider.getList("screening-report-list-view", {
      filter: {
        subscriberID: appRecords?.SubscriberID,
        propertyID: appRecords?.propertyID,
      },
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    });

    const reportNames = data.map((report) => ({
      name: report.name,
      forceOrder: report.forceOrder,
      preSelected: report.preSelected,
    }));
    console.log("reportNames 1", reportNames);

    if (reportNames) {
      setPsReportsData(reportNames);
    }
  };

  // get predictscreen reports

  // get Applicants Data
  const fetchApplicantsListDetails = async () => {
    console.log("appRecords?.id", appRecords?.id, appRecords?.SubscriberID);
    const { data } = await dataProvider.getList(
      "get_subscriber_inbox_detials",
      {
        filter: {
          ApplicationNumber: appRecords?.id,
          subscriberid: appRecords?.SubscriberID,
        },
        pagination: { page: 0, perPage: 0 },
        sort: { field: "", order: "DESC" },
      }
    );
    console.log("data678", data);
    if (data) {
      setapplicantData(data);
    }
  };

  const fetchSubscriberPSDetails = async () => {
    console.log("appRecords?.id", appRecords?.id, appRecords?.SubscriberID);
    const { data } = await dataProvider.getList("get-subscriber-data-ps", {
      filter: {
        subscriberID: appRecords?.SubscriberID,
      },
      pagination: { page: 0, perPage: 0 },
      sort: { field: "", order: "DESC" },
    });
    console.log("data678", data);
    if (data) {
      console.log("data[0][0]", data[0][0]);
      setsubscriberPsData(data[0]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriberPSDetails();
    fetchScreeningReportListDetails();
  }, []);

  //  get Applicants data

  // const validationSchemaShape = {
  //   ...(validateReports && {
  //     reports: Yup.array()
  //       .test("is-selected", "At least one report must be selected", function (
  //         value
  //       ) {
  //         if (!value) return false;
  //         return value.length > 0;
  //       })
  //       .of(Yup.string().required("Report is required")),
  //   }),
  // };

  const validationSchemaShape = {
    ...(validateReports && {
      reports: Yup.array()
        .test("is-selected", "At least one report must be selected", function (
          value
        ) {
          const hasForceOrderSelected = psReportsData.some(
            (report) => report.forceOrder === true || report.forceOrder === 1
          );

          // If there's a forceOrder report, skip the "At least one report" validation
          if (hasForceOrderSelected) {
            return true;
          }

          // If no forceOrder report exists, check if at least one report is selected
          if (!value || value.length === 0) {
            return false;
          }

          return true;
        })
        .of(Yup.string().required("Report is required")),
    }),
  };

  const validationSchema = Yup.object(validationSchemaShape);

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    const initialReports = getValues("reports") || [];

    const newInitialReports = [...initialReports];

    psReportsData.forEach((report) => {
      const isForceOrder =
        report.forceOrder === 1 || report.forceOrder === true;
      const isPreSelected =
        report.preSelected === 1 || report.preSelected === true;

      console.log(
        report.name,
        isPreSelected &&
          !initialReports.includes(report.name) &&
          isForceOrder == false
      );

      // Add preSelected reports to the initial array if not already included and not forceOrder
      if (
        isPreSelected &&
        !initialReports.includes(report.name) &&
        isForceOrder == false
      ) {
        newInitialReports.push(report.name);
      }
    });

    // Set the reports only once after gathering all initial values
    setValue("reports", newInitialReports, { shouldDirty: true });
  }, [psReportsData, getValues, setValue]);

  const onSubmit = (data) => {
    console.log("Form data:", data);

    console.log("psReportsData", psReportsData);
    const selectedReports = psReportsData
      .filter(
        (repo) =>
          (repo.preSelected != null &&
            (repo.preSelected === 1 || repo.preSelected === true)) ||
          (repo.forceOrder != null &&
            (repo.forceOrder === 1 || repo.forceOrder === true))
      )
      .map((report, index) => report.name);
    console.log("selectedReports", selectedReports);

    // payload for manual prepaid
    const selectedReportsPrePaid = data.reports || [];
    const forceOrderReports = psReportsData
      .filter((report) => report.forceOrder === true || report.forceOrder === 1)
      .map((report) => report.name);

    const orderReportsPrePaid = Array.from(
      new Set([...selectedReportsPrePaid, ...forceOrderReports])
    );

    let orderReports: any = [];
    if (validateReports) {
      orderReports = orderReportsPrePaid;
    } else {
      orderReports = selectedReports;
    }

    const payload: Payload = {
      trnID: "",
      selectedReports: orderReports,
    };

    console.log("payload55", payload);

    applicantData.forEach((applicant, index) => {
      if (applicant.Trnid) {
        payload.trnID = applicant.Trnid;
      }
    });

    console.log("Payload: 12333", payload);

    create(
      "orderReportManual",
      { data: payload },
      {
        onSuccess: (data) => {
          console.log("data", data);

          if (validateReports) {
            const paymentUrl = data.records.paymentUrl;
            console.log(paymentUrl, "isCheck1");
            if (paymentUrl) {
              console.log(paymentUrl, "isCheck");
              const link = document.createElement("a");
              link.href = paymentUrl;
              link.target = "_blank"; // Open in a new tab
              document.body.appendChild(link);

              link.click();

              document.body.removeChild(link);
            }
          }

          notify(data.message, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          reset();
          handleClose();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  return (
    <>
      {!loading &&
        subscriberPsData[0]?.paymentMethod !== "endOfTheMonthBilling" && (
          <MuiButton
            color={"primary"}
            onClick={handleOpen}
            variant="contained"
            sx={{
              textTransform: "capitalize",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Process Screening
          </MuiButton>
        )}

      <Dialog
        fullWidth
        open={open}
        maxWidth={false}
        PaperProps={{
          sx: { height: "auto", width: "400px" },
        }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle>Process Screening</DialogTitle> */}
        <DialogContent>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="h6" gutterBottom>
                {subscriberPsData[0]?.paymentMethod ==
                "prepayBeforeOrderingReport"
                  ? "Select Reports"
                  : "Preselected Reports"}
              </Typography>
              <Box display="flex" flexWrap="wrap">
                <FormGroup row>
                  {subscriberPsData &&
                  subscriberPsData[0]?.paymentMethod ==
                    "prepayBeforeOrderingReport"
                    ? psReportsData.map((report, index) => (
                        <Box key={index} width="50%">
                          <Controller
                            name="reports"
                            control={control}
                            render={({ field }) => {
                              const isForceOrder =
                                report.forceOrder === 1 ||
                                report.forceOrder === true;
                              const isPreSelected =
                                report.preSelected === 1 ||
                                report.preSelected === true;
                              const isChecked =
                                field.value.includes(report.name) ||
                                isForceOrder;

                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...field}
                                      checked={isChecked}
                                      disabled={isForceOrder} // Disable checkbox if forceOrder is true or 1
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        const reportName = report.name;

                                        // Only allow changes if not forceOrder
                                        if (!isForceOrder) {
                                          if (checked) {
                                            field.onChange([
                                              ...field.value,
                                              reportName,
                                            ]);
                                          } else {
                                            field.onChange(
                                              field.value.filter(
                                                (v) => v !== reportName
                                              )
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  }
                                  label={report.name}
                                  sx={{ marginRight: 2 }}
                                />
                              );
                            }}
                          />
                        </Box>
                      ))
                    : subscriberPsData[0]?.paymentMethod == "applicationCollect"
                    ? psReportsData
                        .filter(
                          (repo) =>
                            (repo.preSelected != null &&
                              (repo.preSelected === 1 ||
                                repo.preSelected === true)) ||
                            (repo.forceOrder != null &&
                              (repo.forceOrder === 1 ||
                                repo.forceOrder === true))
                        )
                        .map((report, index) => (
                          <Box key={index} width="50%" className="mb-2">
                            <Typography variant="body1">
                              {report.name}
                            </Typography>
                          </Box>
                        ))
                    : ""}
                </FormGroup>
                {psReportsData.length <= 0 && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "red",
                      marginTop: "5px",
                    }}
                  >
                    Reports are not available
                  </Typography>
                )}
              </Box>
              {errors.reports && psReportsData.length > 0 && (
                <Typography color="error">{errors.reports.message}</Typography>
              )}
              <Box display="flex" justifyContent="center" mt={3} mb={1}>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    handleClose();
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button sx={{ mx: 1 }} variant="contained" type="submit">
                  Submit
                </Button>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProcessScreeningModal;

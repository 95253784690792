import {
  Datagrid,
  FunctionField,
  LinearProgress,
  ListBase,
  ListToolbar,
  Pagination,
  TextField,
  Title,
  useCreate,
  useDataProvider,
  useGetIdentity,
  useListContext,
  useStore,
  useGetList,
} from "react-admin";
import {
  Grid,
  Typography,
  TextField as MuiTextField,
  Button,
  Box,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Divider,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";

// screening-report-list

const ScreeningReportsList = () => {
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const navigate = useNavigate();
  type ScreeningReport = {
    subscriberName: string;
    propertyName: string;
    propertyID: number;
    officeID: number;
    officeName: string;
  };
  const [dataScreen, setDataScreen] = useState<ScreeningReport[]>([]);
  const [reportsScreen, setReportsScreen] = useState<{
    [key: number]: { name: string; color: string }[];
  }>({});
  const dataProvider = useDataProvider();
  const [expanded, setExpanded] = useState<number | false>(false);
  // const { data, isLoading } = useListContext();
  const {
    data: getScreeningReportDetails,
    isLoading: loadingEScreeningReportDetails,
  } = useGetList(
    "screening-report-list",
    {
      filter: {
        subscriberID: switchSubscriberObj?.value,
      },
    },
    {
      enabled: switchSubscriberObj?.value ? true : false,
      refetchOnWindowFocus: false,
    }
  );

  // const {
  //   data: getScreeningReportListDetails,
  //   isLoading: loadingScreeningReportListDetails,
  // } = useGetList(
  //   "screening-report-list-view",
  //   {
  //     filter: {
  //       subscriberID: 10301,
  //       officeId: 167,
  //     },
  //   },
  //   {
  //     enabled: switchSubscriberObj?.value ? true : false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useEffect(() => {
    if (getScreeningReportDetails && getScreeningReportDetails.length > 0) {
      const data = getScreeningReportDetails;
      console.log("data", data);
      setDataScreen(getScreeningReportDetails);
    }
  }, [getScreeningReportDetails]);

  const handleAccordionChange =
    (officeID: number) =>
    async (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? officeID : false);

      if (isExpanded && !reportsScreen[officeID]) {
        // Fetch reports when the accordion is expanded
        const { data } = await dataProvider.getList(
          "screening-report-list-view",
          {
            filter: {
              subscriberID: switchSubscriberObj?.value,
              propertyID: officeID,
            },
            pagination: { page: 1, perPage: 10 },
            sort: { field: "id", order: "ASC" },
          }
        );

        console.log("data", data);
        // const reportNames = data.map((report) => report.name);
        const reportNames = data.map((report) => {
          let color = "black";
          if (report.forceOrder == 1) {
            color = "red";
          } else if (report.preSelected == 1) {
            color = "#2CC0E4";
          }
          return { name: report.name, color };
        });

        console.log("reportNames", reportNames);
        setReportsScreen((prevReports) => ({
          ...prevReports,
          [officeID]: reportNames,
        }));
      }
    };

  return (
    <>
      <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
        {/* Left side with title */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Screening Reports</Typography>
        </Grid>

        {/* Right side with search input, filter button, and add button */}
        <Grid
          item
          xs={12}
          md={8}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* Search Input */}
          {/* <MuiTextField
          placeholder="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          
          
          variant="outlined"
          size="small"
        /> */}
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 1,
                borderRadius: 1,
                marginLeft: 1,
              }}
            ></Box>
            <Typography
              variant="caption"
              sx={{ color: "black", fontSize: "0.75rem" }}
            >
              Force Order
            </Typography>
          </Box>{" "}
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "#2CC0E4",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 1,
              borderRadius: 1,
              marginLeft: 1,
            }}
          ></Box>
          <Typography
            variant="caption"
            sx={{ color: "black", fontSize: "0.75rem" }}
          >
            Pre Selected
          </Typography>{" "}
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 1,
              borderRadius: 1,
              marginLeft: 1,
            }}
          ></Box>
          <Typography
            variant="caption"
            sx={{ color: "black", fontSize: "0.75rem" }}
          >
            Not Selected
          </Typography>
        </Grid>
      </Grid>

      <div className="mt-2">
        {dataScreen.map((record) => (
          <Accordion
            key={record?.propertyID ? record?.propertyID : record?.officeID}
            onChange={handleAccordionChange(
              record?.propertyID ? record?.propertyID : record?.officeID
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-content-${
                record?.propertyID ? record?.propertyID : record?.officeID
              }`}
              id={`panel-header-${
                record?.propertyID ? record?.propertyID : record?.officeID
              }`}
            >
              <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>
                {record?.propertyName
                  ? `Property Name: ${record.propertyName}`
                  : `Office Name: ${record.officeName}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={2}
                >
                  {/* <Typography variant="h6" sx={{ fontSize: '0.875rem' }}>Property Name</Typography> */}
                  <Box flex={1}>
                    <Typography variant="h6" sx={{ fontSize: "0.875rem" }}>
                      Reports
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ marginY: 1 }} />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box flex={1}>
                    {loadingEScreeningReportDetails ? (
                      <CircularProgress size={24} />
                    ) : reportsScreen[
                        record.propertyID ? record.propertyID : record.officeID
                      ] &&
                      reportsScreen[
                        record.propertyID ? record.propertyID : record.officeID
                      ].length > 0 ? (
                      <Box>
                        {reportsScreen[
                          record.propertyID
                            ? record.propertyID
                            : record.officeID
                        ].map((report, index) => (
                          <Typography
                            key={index}
                            style={{
                              color: report.color,
                              fontSize: "15px",
                              fontWeight: "600",
                            }}
                          >
                            {report.name}
                          </Typography>
                        ))}
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "red",
                        }}
                      >
                        Reports not available
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default ScreeningReportsList;

import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/DocuVerusLogoorg.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import { FormProvider, useForm } from "react-hook-form";
// import { toast, ToastContainer } from "react-toastify";
import {
  Outlet,
  Link,
  useNavigate,
  Navigate,
  useParams,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import {
  Button,
  useCreate,
  useNotify,
  useRefresh,
  useGetList,
} from "react-admin";
import {
  setaspNetUserId,
  setIsLoggedIn,
  setLoginData,
  setToken,
  setUserRole,
} from "../../Store/Slice/usersSlice";
import { ATS_DOCS_URL, SOFTWARE_VERSION, typesofUsers } from "../../config";
import { CircularProgress } from "@mui/material";
import { useLogin, Notification } from "react-admin";
import "../../assets/styles/style.css";
import ForgotPassword from "./ForgotPassword";
import DataPrivacy from "./DataPrivacy";
import TermsOfUse from "./TermOfUse";
import MfaLogin from "./MfaLogin";
import Inbox from "../ATS/Inbox";
import { useDataProvider } from "react-admin";

type DecodedObjType = {
  userrole?: string;
  subscriberView?: boolean;
  subUserId?: string;
  subscriberid?: string;
};

const SubscriberLogin = (props) => {
  const navigate = useNavigate();
  const { subData } = useParams<{ subData?: string }>();
  const dataProvider = useDataProvider();

  const data: string | undefined = subData;

  console.log("data 1", data);

  let decodedObj: DecodedObjType | null = null;
  if (data) {
    decodedObj = JSON.parse(decodeURIComponent(data));
  }

  console.log(decodedObj);

  const checkSubscriberPsEnabled = async (subId) => {
    console.log("subId", subId);
    const { data } = await dataProvider.getList("check-subscriber-enabled-ps", {
      filter: {
        subscriberID: subId,
      },
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    });

    console.log("psdata", data[0]);
    if (data[0] != undefined || data[0] != null) {
      console.log("psdata4", data[0]);
      localStorage.setItem("PsEnabledSubscribers", JSON.stringify(data[0]));
    } else {
      console.log("psdata5", data[0]);
      localStorage.setItem(
        "PsEnabledSubscribers",
        JSON.stringify({ isEnabled: false })
      );
    }
  };

  useEffect(() => {
    document.title = "PredictScreen";
  
    var subId = decodedObj?.subUserId || "";
    var subUserId = decodedObj?.subscriberid || "";

    if (decodedObj) {
      const userRole = decodedObj?.userrole || "";

      localStorage.setItem("userRole", `"${userRole}"`);
      localStorage.setItem("orginalUserRole", `"${userRole}"`);

      if (subId) {
        decodedObj.subscriberView = false;
        localStorage.setItem("RaStore.switchSubscriber", "false");
        localStorage.setItem("isPsLogo", "true");
      } else {
        console.log("subUserId:", subUserId);
        decodedObj.subscriberView = true;
        localStorage.setItem("RaStore.switchSubscriber", "true");
        localStorage.setItem("showSwitchBtn", "false");
        localStorage.setItem("isPsLogo", "true");
        checkSubscriberPsEnabled(subUserId);
      }
    }

    localStorage.setItem("auth", JSON.stringify({ data: decodedObj }));

    if (localStorage.getItem("auth")) {
      //navigate("/Ats");

      console.log("subId", subId);
      if (subId) {
        console.log("subId 2", subId);
        navigate(`/ats/manageSubscribers/subscriberDetails/${subId}`, {
          state: { isAdding: false },
        });
      } else {
        console.log("subId 5", subId);
        navigate("/ats");
      }
    }
  }, []);

  return <></>;
};

export default SubscriberLogin;

export const ATS_API_URL = process.env.REACT_APP_ATS_API_URL;
export const LCIJ_API_URL = "https://lcij-ats-api.dvapply.com";
export const REACT_APPLICANT=process.env.REACT_APP_REACT_APPLICANT;
export const ATS_DOCS_URL = process.env.REACT_APP_ATS_DOCS_URL;
export const ADDRESS_API_KEY = process.env.REACT_APP_ADDRESS_API_KEY;
export const OLD_DOCUPLOAD_API =process.env.REACT_APP_OLD_DOCUPLOAD_API;
export const DOCUPLOAD_SUB_URL_FRONT = process.env.REACT_APP_DOCUPLOAD_SUB_URL_FRONT;
export const pk = "pk_live_IaVLDOel1ZP3b76vZKamCSEC";
export const sk = "sk_live_BubD7nMJ7OxoeB0JAImHS7Oz00VzfBmkce";
export const SOFTWARE_VERSION=process.env.REACT_APP_SOFTWARE_VERSION || '3.0.5'

export const typesofUsers = { 
  superAdmin: "Super Admin",
  admin: "Admin",
  agent: "Agent",
  manager: "Manager",
  applicant: "Applicant",
  coApplicant: "Co-applicant",
  coSigner: "Co-signer",
  subscriberUser: "Subscriber User",
  subscriberAdmin: "Subscriber Admin",
  docuverusSuperAdmin: "Docuverus Super Admin",
  landlordThirdPartyAgent: "Landlord Third Party Agent",
  docuverusAdmin:"Docuverus Admin",
};
